
export function FPOverlay(image, map, bearings, vertices, target) {
  /*
  console.log("FPOverlay constructor enter");
  console.log("image:" + image);
  console.log("map:" + map);
  console.log("bearings:" + JSON.stringify(bearings));
  console.log("vertices:" + JSON.stringify(vertices));
  */
  this.image    = image;
  this.bearings = bearings;
  this.vertices = vertices;
  this.map      = map;
  this.div      = null;
  this.setMap(map);
  console.log("FPOverlay constructor exit");
}

export function onAddFloorPlanOverlay() {
  console.log('FloorplanOverlay.onAddFloorPlanOverlay start');
	this.div = document.createElement('div');
	this.div.style.cssText='border:0px none;position:absolute';
	this.getPanes().overlayLayer.appendChild(this.div);
  console.log('FloorplanOverlay.onAddFloorPlanOverlay end');
};

// Remove overlay without removing the div
export function removeFloorPlanOverlay() {
  console.log('FloorplanOverlay.removeFloorPlanOverlay start');
	this.setMap(null);
  console.log('FloorplanOverlay.removeFloorPlanOverlay start');
}

export function showFloorPlanOverlay(map) {
  this.setMap(map);
}
