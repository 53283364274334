import { combineReducers } from 'redux';
import auth from 'reducers/authReducer';
import devices from 'reducers/devicesReducer';
import locations from 'reducers/locationsReducer';
import { reducer as formReducer } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

export default combineReducers({
  auth,
  devices,
  locations,
  form: formReducer,
  notifications,
  window
})
