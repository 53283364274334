import axios from "axios";

let url_config = {
  headers: {
    'Accept': 'application/json'
  }
}
/*
if (process.env.REACT_APP_HOST_ENV==='development') {
  url_config = {
    headers: {
      'X-Auth-User': 'jacobcantwell',
      'X-Auth-Key': '410bbc3e24e06f5016ef2f190f3b543b'
    }
  }
}
*/

export function fetchDevices() {
  return function(dispatch) {
    dispatch({type: 'FETCH_DEVICES'});
    const path = '/v1/locations';
    // const url = `${process.env.REACT_APP_API_SERVER_PROTOCOL}://${process.env.REACT_APP_API_SERVER_URL}` + path
    const url = "https://node-red.locatorlab.info/api/v1/locations"
    axios
      .get(url, url_config)
      .then(response => {
        console.log(path,response);
        console.log("response.data",response.data);
        const devices = response.data;
        dispatch({type: "FETCH_DEVICES_FULFILLED", payload: devices})
      })
      .catch(err => {
        dispatch({type: "FETCH_DEVICES_REJECTED", payload: err})
      });
  }
}

export function fetchDevice(id) {
  return function(dispatch) {
    dispatch({type: 'FETCH_DEVICE'});
    const path = '/v1/locations/' + id;
    const url = `${process.env.REACT_APP_API_SERVER_PROTOCOL}://${process.env.REACT_APP_API_SERVER_URL}` + path
    axios
      .get(url)
      .then(response => {
        console.log(path,response);
        let device = response.data;
        device.id = device.deviceId;
        dispatch({type: "FETCH_DEVICE_FULFILLED", payload: device})
      })
      .catch(err => {
        dispatch({type: "FETCH_DEVICE_REJECTED", payload: err})
      });
  }
}

export function addDevice(id, batteryPercent, location, name, status, type, url) {
  return {
    type: 'ADD_DEVICE',
    payload: { id, batteryPercent, location, name, status, type, url }
  }
}

export function updateDevice(id, batteryPercent, location, name, status, type, url) {
  return {
    type: 'UPDATE_DEVICE',
    payload: { id, batteryPercent, location, name, status, type, url }
  }
}

export function deleteDevice(id) {
  return { type: 'DELETE_DEVICE', payload: id}
}
