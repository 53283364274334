import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { signoutUser } from 'actions/authActions'

class SignoutPage extends React.Component {
  
  constructor(props) {
    super(props)
    this.props.dispatch( signoutUser() );
  }

  render() {
    return (
      <Redirect to="/?message=goodbye"/>
    )
  }
}

export default connect(null)(SignoutPage);