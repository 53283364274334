import React from 'react'
import { connect } from 'react-redux'
import { fetchDevices } from 'actions/devicesActions'
import { Segment } from 'semantic-ui-react';
import ToolbarWrapper from 'components/ToolbarWrapper'
import DevicesTable from 'components/devices/DevicesTable'
import _ from 'lodash';

class DevicesPage extends React.Component {
  constructor(props) {
    super(props)
    if (!props.fetched) {
      props.getDevices();
    }
  }
  render() {
    const { getDevices, isAuthenticated, fetching, devices, userName, userRole } = this.props;

    // TODO: move this logic server side
    var filteredDevices = devices;
    if ('bhs' === userRole) {
      console.log('unfiltered devices',devices.length);
      filteredDevices = _.filter(devices, function(o) { return o.deviceFriendlyName.startsWith('Asset') });
      console.log('filtered devices',filteredDevices.length);
    }

    return (
      <ToolbarWrapper
        activeItem='devices'
        isAuthenticated={isAuthenticated}
        userName={userName}
        userRole={userRole}
        contentFetching={fetching}
        contentCount={filteredDevices}
        refreshFunction={getDevices}
        refreshLabel='Devices'
      > 
        <Segment loading={fetching} basic>
          <DevicesTable fetching={fetching} devices={filteredDevices} userRole={userRole} />
        </Segment>
      </ToolbarWrapper>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getDevices: () => { dispatch(fetchDevices()) }
  }
}

const mapStateToProps = store => {
  console.log('DevicesPage store', store);
  return {
    isAuthenticated: store.auth.isAuthenticated,
    userName: store.auth.userName,
    userRole: store.auth.userRole,
    fetching: store.devices.fetching,
    fetched: store.devices.fetched,
    devices: store.devices.devices,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage);
