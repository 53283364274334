import jwtDecode from 'jwt-decode';

const initialState = {
  token: null,
  userName: null,
  userRole: null,
  isAuthenticated: false,
  isAuthenticating: false,
  isWelcomed: false,
  statusText: null
};

export default function reducer(state={initialState}, action) {
    switch (action.type) {
      case 'SIGNIN_USER': {
        return {
          ...state,
          isAuthenticating: true,
          statusText: null
        }
      }
      case 'SIGNIN_USER_FULFILLED': {
        let userName, userRole;
        try {
          const decodedToken = jwtDecode(action.payload.token);
          console.log('decodedToken:',decodedToken);
          if (decodedToken.name) {
            userName = decodedToken.name;
          }
          if (decodedToken.role) {
            userRole = decodedToken.role;
          }
        } catch (error) {
          console.log(error)
          return {
            ...state,
            isAuthenticating: false,
            isAuthenticated: false,
            token: null,
            userName: null,
            userRole: null,
            statusText: 'Invalid username or password.',
          }
        }
        return {
          ...state,
          isAuthenticating: false,
          isAuthenticated: true,
          token: action.payload.token,
          userName: userName,
          userRole: userRole,
          statusText: 'You have successfully signed in.',
        }
      }
      case 'SIGNIN_USER_REJECTED': {
        return {
          ...state,
          isAuthenticating: false,
          isAuthenticated: false,
          isWelcomed: false,
          token: null,
          userName: null,
          statusText: 'Invalid username or password.',
        }
      }
      case 'SIGNIN_USER_WELCOMED': {
        return {
          ...state,
          isWelcomed: true,
        }
      }
      case 'SIGNOUT_USER': {
        return {
          ...state,
          isAuthenticated: false,
          isWelcomed: false,
          token: null,
          userName: null,
          statusText: 'You have successfully signed out.',
        }
      }
      default:
        break;
    }
    return state
}
