import React from 'react'
import { Button, Card, Header } from 'semantic-ui-react'

class PageHeaderCard extends React.Component {
  render() {
    const { contentCount, isLoading, label, refreshFunction } = this.props; 
    let buttonLabel = 'Refresh'
    if (contentCount && contentCount.length) {
      buttonLabel = contentCount.length.toLocaleString() + ' Entries'
    }
    return (
      <Card fluid>
        <Card.Content>
          <Header floated='left' size='large'>{label}</Header>
          { refreshFunction ?
            <Button floated='right' label={buttonLabel} icon='refresh' labelPosition='left' onClick={refreshFunction} disabled={isLoading} />
            : null
          }
        </Card.Content>
      </Card>
    )
  }
}
export default PageHeaderCard
