import React from 'react'
import { Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';

const locations = {
  'HBG': 'Henry Bolte Ground',
  'HB1': 'Henry Bolte 1',
  'HB2': 'Henry Bolte 2',
  'HB3': 'Henry Bolte 3',
  'HB4': 'Henry Bolte 4',
  'HB5': 'Henry Bolte 5',
  'GP2': 'Gardiner Pittard 2',
  '2-242X': 'Telstra Labs'
}
function formatCoordinate(coordinate) {
  if (coordinate) {
    return coordinate.toFixed(5);
  }
  return 'Unknown';
}
function formatLocation(locationId, untrackedLocation) {
  if (untrackedLocation) {
    return 'Untracked Floor'
  }
  if (locations[locationId]) {
    return locations[locationId];
  }
}
function isKnownLocation(latitude, longitude) {
  console.log('isKnownLocation');
  if (latitude && longitude) {
    return true;
  }
}
export default class DevicesTable extends React.Component {
  render() {
    const { devices } = this.props;
    let content = null;
    if (Array.isArray(devices)) {
      content = (
        <Table.Body>
          {
            devices.map(function(device, index){
            const knownLocation = isKnownLocation(device.lastLatitude, device.lastLongitude);
            return (
              <Table.Row key={index}>
                <Table.Cell textAlign='center'>{index + 1}</Table.Cell>
                <Table.Cell>
                {
                  knownLocation?
                    <Link to={`/devices/${device.id}`}>{device.deviceFriendlyName}</Link>:
                    device.deviceFriendlyName
                }
                </Table.Cell>
                <Table.Cell>{device.profile}</Table.Cell>
                <Table.Cell>{device.wifiMacAddress}</Table.Cell>
                <Table.Cell>{formatCoordinate(device.lastLatitude)}</Table.Cell>
                <Table.Cell>{formatCoordinate(device.lastLongitude)}</Table.Cell>
                <Table.Cell>{formatLocation(device.floorId, device.untrackedLocation)}</Table.Cell>
                <Table.Cell>{device.accessPointName}</Table.Cell>
                <Table.Cell textAlign='center'>{(device.lastBatteryLevel) > -1?device.lastBatteryLevel+'%':'Unknown'}</Table.Cell>
                <Table.Cell><Moment fromNow>{device.lastSignalDateTime}</Moment></Table.Cell>
              </Table.Row>
            )
          }
          )}
        </Table.Body>
      )
    } 

    return (
      <Table celled striped color='purple'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>#</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>MAC Address</Table.HeaderCell>
            <Table.HeaderCell>Latitude</Table.HeaderCell>
            <Table.HeaderCell>Longitude</Table.HeaderCell>
            <Table.HeaderCell>Floor Name</Table.HeaderCell>
            <Table.HeaderCell>Access Point Name</Table.HeaderCell>
            <Table.HeaderCell>Battery Level</Table.HeaderCell>
            <Table.HeaderCell>Last Signal Received</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {content}
      </Table>
    )
  }
}