import { parseJSON } from '../utils'
// import jwtDecode from 'jwt-decode';
import { SubmissionError } from 'redux-form'

export function signinUserSuccess(token) {
  localStorage.setItem('token', encodeURIComponent(token));
  return {
    type: 'SIGNIN_USER_FULFILLED',
    payload: {
      token: token
    }
  }
}

export function signinUserFailure(error) {
  localStorage.removeItem('token')
  return {
    type: 'SIGNIN_USER_REJECTED',
  }
}

export function signinUserRequest() {
  return {
    type: 'SIGNIN_USER'
  }
}

export function signinUser(email, password, redirect="/") {
  return function(dispatch) {
    dispatch(signinUserRequest())
    const path = '/v1/authenticate'
    const url = `${process.env.REACT_APP_AUTHENTICATION_SERVER_PROTOCOL}://${process.env.REACT_APP_AUTHENTICATION_SERVER_URL}` + path
    let url_config = {
      method: 'post',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, password: password})
    }
    if (process.env.REACT_APP_HOST_ENV==='development') {
      url_config = {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Auth-User': 'jacobcantwell',
          'X-Auth-Key': '410bbc3e24e06f5016ef2f190f3b543b'
        },
        body: JSON.stringify({email: email, password: password})
      }
    }
    return fetch(url, url_config)
      .then(function(response) {
        console.log('authAction.signinUser.response',response);
        if(response.status === 401) {
          throw new SubmissionError({
            status: 'SubmissionError',
            _error: 'Invalid username or password.'
          });
        }
        else if(response.status !== 200) {
          throw new SubmissionError({
            status: 'SubmissionError',
            _error: 'Unknown submission error.'
          });
        }
        else {
          return response;
        }
      })
      .then(parseJSON)
      .then(response => {
        dispatch(signinUserSuccess(response.token))
      })
      .catch(error => {
        console.log(error);
        dispatch(signinUserFailure(error));
      })
  }
}

export function signoutUserSuccess() {
  return {
    type: 'SIGNOUT_USER_FULFILLED'
  }
}

export function signoutUserRequest() {
  return {
    type: 'SIGNOUT_USER'
  }
}

export function signoutUser() {
  return function(dispatch) {
    dispatch(signoutUserRequest());
    localStorage.removeItem('token');
    dispatch(signoutUserSuccess());
  }
}
