import React from 'react';
import GoogleMap from 'google-map-react';
import SimpleMarker from './markers/SimpleMarker';
import ballaratBaseHospitalData from './data/ballarat-base-hospital.json';
import telstra242ExhibitionData from './data/telstra-242-exhibition.json';

const GOOGLE_MAP_API_KEY = 'AIzaSyAl90JBXyshAIPe6oxmJyNSEDvpOfnGnHk';
const mapKeys = {
  key: GOOGLE_MAP_API_KEY,
  language: 'en-AU',
  region: 'au',
};

function getMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    mapTypeId: 'roadmap',
    minZoom:21,
    maxZoom:21,
    zoom:21,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true
  };
}

function onLocationsMapLoaded ({map, maps}) {
  // console.log('onLocationsMapLoaded', map, maps);
  // var geojson = geoString;

  map.data.addGeoJson(ballaratBaseHospitalData);
  map.data.addGeoJson(telstra242ExhibitionData);
  
  // centerMapOnBallaratHospital(map);
  // map.data.remove(geojson);
  // Set the stroke width, and fill color for each polygon
  map.data.setStyle(function(feature) {
      var icon = feature.getProperty('icon');
      if ('wifi' === icon) {
        return {
          strokeWeight: 1,
          icon: {
            path: 'M -4,0 0,-4 4,0 0,4 z',
            strokeColor: 'red',
            fillColor: 'red'
          },
          /*
          label: name,
          icon: {
            path: 'M -70,-10 -70,10 70,10 70,-10 z',
            strokeColor: 'white',
            fillColor: 'white',
            fillOpacity: 1
          },
          */
          zIndex: 80
        };
      } else if ('floorplan-image' === icon) {
        console.log('adding floorplan-image',map.zoom);
        var floorplanUrl = feature.getProperty('url');
        // var floorplanWidth = feature.getProperty('width');
        // var floorplanHeight = feature.getProperty('height');
        var iconStyle = {
          strokeWeight: 5,
          icon: { 
            url: floorplanUrl
            // scaledSize: new google.maps.Size(300, 400)
          },
          zIndex: 1
        }
        console.log('iconStyle',iconStyle);
        return iconStyle;
      } else if ('floorplan-image-guide' === icon) {
          return {
            icon: {
              path: 'M -3,0 0,-3 3,0 0,3 z',
              strokeColor: 'black',
              fillColor: 'white',
              fillOpacity: 1
            } 
          }
      } else if ('floorplan' === icon) {
        return {
          fillColor: 'green',
          strokeColor: 'black',
          strokeWeight: 1,
          zIndex: 1
        };
      }
      /* var color = icon > 91 ? 'green' : 'blue';
      return {
        // fillColor: color,
        strokeWeight: 1,
        title: feature.getProperty('name'),
        icon: icon,
        // offset: '-20'
      };
      */
  });
}

class LocationsMap extends React.Component {
  _onChildClick = (key, childProps) => {
    console.log('_onChildClick', key, childProps);
  }
  _onChildMouseEnter = (key /*, childProps */) => {
    console.log('_onChildMouseEnter');
  }
  _onChildMouseLeave = (/* key, childProps */) => {
    console.log('_onChildMouseLeave');
  }
  render() {
    const { centerLocation, deviceLocations, handleMapBoundsChange, handleMapClick, handleMarkerClick} = this.props;
    const markers = deviceLocations.map((marker, index) => (
      <SimpleMarker
        // required props
        key={marker.id}
        lat={marker.lastLatitude}
        lng={marker.lastLongitude}
        // user props
        id={marker.id}
        title={marker.deviceFriendlyName}
        text={index}
        handleMarkerClick={handleMarkerClick} />
    ));
    return (
      <GoogleMap
        options={getMapOptions}
        bootstrapURLKeys={mapKeys}
        center={centerLocation.center}
        zoom={centerLocation.zoom}
        hoverDistance={20 / 2}
        onClick={handleMapClick}
        onBoundsChange={handleMapBoundsChange}
        onChildClick={handleMarkerClick}
        onChildMouseEnter={this._onChildMouseEnter}
        onChildMouseLeave={this._onChildMouseLeave}
        onGoogleApiLoaded={onLocationsMapLoaded}
        yesIWantToUseGoogleMapApiInternals>
        {markers}
      </GoogleMap>
    )
  }
}
export default LocationsMap;
