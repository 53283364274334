import React from 'react';
import { Header, Message, Responsive } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'
import ToolbarWrapper from 'components/ToolbarWrapper';

class DashboardPage extends React.Component {
  render() {
    const { isAuthenticated, userName } = this.props

    return (
      <ToolbarWrapper activeItem='dashboard' isAuthenticated={isAuthenticated} userName={userName}>
        <Header as='h1'>Dashboard</Header>
        
        <Message info>
          <Message.Header>Welcome {userName}</Message.Header>
          <p>Welcome</p>
        </Message>

        <Message negative icon='warning sign'
          header='Warning' 
          content='There are 2 devices with low battery level.' />

        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        </Responsive>

      </ToolbarWrapper>
    )
  }
}
export default DashboardPage;
