import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

class SigninPage extends React.Component {

  render() {
    const { isAuthenticated } = this.props
    return (
      isAuthenticated ? (
        <Redirect to='/dashboard/welcome' />
      ) : (
        <Redirect to='/' />
      )
    )
  }
}

const mapStateToProps = store => {
  return {
    isAuthenticated: store.auth.isAuthenticated,
  }
}
export default connect(mapStateToProps)(SigninPage)
