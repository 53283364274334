import React from 'react'  
import { Link } from 'react-router-dom'
import { Dropdown, Icon, Menu } from 'semantic-ui-react'

/*
<Menu.Item as={Link} active={activeItem==='locations'} to='/locations'>
  <Icon name='map'/> Locations
</Menu.Item>
<Menu.Item as={Link} active={activeItem==='gateways'} to='/gateways'>
  <Icon name='rss'/> Gateways
</Menu.Item>
<Menu.Item>
  <Icon name='bar chart'/> Measurements
  <Menu fluid={isMobileView} secondary={isMobileView} inverted={!isMobileView} vertical>
    <Menu.Item as={Link} active={activeItem==='measurements'} to='/measurements'>
      History
    </Menu.Item>
    <Menu.Item as={Link} active={activeItem==='messages-live'} to='/messages/live'>
      Live
    </Menu.Item>
  </Menu>
</Menu.Item>

<Menu fixed='top' inverted>
  <Container fluid>
    <Menu.Item as='a' header>
      <Image
        size='mini'
        src='/logo.png'
        style={{ marginRight: '1.5em' }}
      />
      Project Name
    </Menu.Item>
    <Menu.Item as='a'>Home</Menu.Item>
    <Dropdown item simple text='Dropdown'>
      <Dropdown.Menu>
        <Dropdown.Item>List Item</Dropdown.Item>
        <Dropdown.Item>List Item</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Header>Header Item</Dropdown.Header>
        <Dropdown.Item>
          <i className='dropdown icon' />
          <span className='text'>Submenu</span>
          <Dropdown.Menu>
            <Dropdown.Item>List Item</Dropdown.Item>
            <Dropdown.Item>List Item</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Item>
        <Dropdown.Item>List Item</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </Container>
</Menu>
*/

class PageMenu extends React.Component {
  render() {
    const { activeItem, contentCount, contentFetching, isAuthenticated, refreshFunction, refreshLabel, userName, userRole } = this.props
    let buttonLabel = refreshLabel;
    if (contentCount && contentCount.length) {
      buttonLabel = contentCount.length.toLocaleString() + ' ' + refreshLabel;
    }
    return (
      <Menu pointing attached='top'>
        { isAuthenticated ? 
          <React.Fragment>
            <Menu.Item as={Link} active={activeItem==='devices'} to='/devices'>
              Devices
            </Menu.Item>
            {(userRole === 'admin')?
              (<Dropdown text='Locations' pointing className='{activeItem.startsWith("locations") ? "link item active" : "link item"}'>
                <Dropdown.Menu>
                  {/*
                  <Dropdown.Item as={Link} active={activeItem==='locations-show-all'} to='/locations/show-all'>Show All Devices</Dropdown.Item>
                  */}
                  <Dropdown.Header>BHS</Dropdown.Header>
                  <Dropdown.Item as={Link} active={activeItem==='locations-ballarat-base-hospital'} to='/locations/ballarat-base-hospital'>Ballarat Base Hospital</Dropdown.Item>
                  <Dropdown.Header>Telstra</Dropdown.Header>
                  <Dropdown.Item as={Link} active={activeItem==='locations-telstra-242-exhibition'} to='/locations/telstra-242-exhibition'>242 Exhibition Street</Dropdown.Item>
                 </Dropdown.Menu>
              </Dropdown>)
              : null
            }
            <Menu.Menu position='right'>
              { refreshFunction ?
                <React.Fragment>
                  <Menu.Item>{buttonLabel}</Menu.Item>
                  <Menu.Item onClick={refreshFunction} disabled={contentFetching}><Icon name='refresh' /></Menu.Item>
                </React.Fragment>
                : null
              }
              <Menu.Item as={Link} to='/signout'>Logout {userName}</Menu.Item>
             </Menu.Menu>
          </React.Fragment>
          : null 
         }
      </Menu>
    )
  }
}
export default PageMenu
