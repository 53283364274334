import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'

export function requireAuthentication(Component) {
    class AuthenticatedComponent extends React.Component {
        render () {
            const { isAuthenticated, userName } = this.props;
            return (
                <div>
                    {this.props.isAuthenticated === true
                        ? <Component {...this.props} 
                            isAuthenticated={isAuthenticated} 
                            userName={userName} />
                        : <Redirect to='/'/>
                    }
                </div>
            )
        }
    }
    const mapStateToProps = store => {
      return {
        isAuthenticated: store.auth.isAuthenticated,
        userName: store.auth.userName,
      }
    }
    return connect(mapStateToProps)(AuthenticatedComponent);
}
