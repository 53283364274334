import axios from "axios";

let url_config = {
  params: {
    api_key: 'panda'
  },
  headers: {
    'Accept': 'application/json',
    'tdi-guid': 'u100'
  }
}

export function fetchLocations() {
  return function(dispatch) {
    dispatch({type: 'FETCH_LOCATIONS'});
    const path = '/tables/Device';
    const url = `${process.env.REACT_APP_API_SERVER_PROTOCOL}://${process.env.REACT_APP_API_SERVER_URL}` + path
    axios
      .get(url, url_config)
      .then(response => {
        console.log(path,response);
        console.log("response.data",response.data);
        const locations = response.data;
        dispatch({type: "FETCH_LOCATIONS_FULFILLED", payload: locations})
      })
      .catch(err => {
        dispatch({type: "FETCH_LOCATIONS_REJECTED", payload: err})
      });
  }
}
