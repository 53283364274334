import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { requireAuthentication } from 'components/AuthenticatedComponent';
import DashboardPage from 'pages/DashboardPage';
import DevicePage from 'pages/DevicePage';
import DevicesPage from 'pages/DevicesPage';
import IndexPage from 'pages/IndexPage';
import LocationsPage from 'pages/LocationsPage';
import NotFoundPage from 'pages/NotFoundPage';
import SigninPage from 'pages/SigninPage';
import SignoutPage from 'pages/SignoutPage';

export default (
  <Router>
    <Switch>
      <Route exact path='/' component={IndexPage} />
      <Route exact path='/signin' component={SigninPage} />
      <Route exact path='/signout' component={SignoutPage} />
      <Route exact path='/dashboard/:message?' component={requireAuthentication(DashboardPage)} />
      <Route exact path='/locations' component={requireAuthentication(LocationsPage)} />
      <Route exact path='/locations/:id' component={requireAuthentication(LocationsPage)} />
      <Route exact path='/devices' component={requireAuthentication(DevicesPage)} />
      <Route exact path='/devices/:id' component={requireAuthentication(DevicePage)} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
)
