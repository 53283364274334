import React from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import ToolbarWrapper from 'components/ToolbarWrapper'
import SigninForm from 'components/SigninForm'

class IndexPage extends React.Component {
  render() {
    const { isAuthenticated, userName } = this.props
    return (
      isAuthenticated ? (
        <Redirect to="/devices"/>
      ) : (
        <ToolbarWrapper activeItem='signin' isAuthenticated={isAuthenticated} userName={userName}>
          <SigninForm />
        </ToolbarWrapper>
      )
    )
  }
}
const mapStateToProps = store => {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    userName: store.auth.userName,
  }
}
export default connect(mapStateToProps)(IndexPage)
