export default function reducer(state={
    locations: [],
    fetching: false,
    fetched: false,
    error: null,
  }, action) {

    switch (action.type) {
      case "FETCH_LOCATIONS": {
        return {...state, fetching: true}
      }
      case "FETCH_LOCATIONS_REJECTED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          locations: action.payload,
        }
      }
      case "FETCH_LOCATIONS_FULFILLED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          locations: action.payload
            .filter(device => ((device.lastLatitude > -181) && (device.lastLongitude > -181)))
            .filter(device => device.deleted !== true)
            .sort((a, b) => b.lastSignalDateTime.localeCompare(a.lastSignalDateTime))
        }
      }
      default:
        break;
    }
    return state
}