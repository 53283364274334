import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchLocations } from 'actions/locationsActions'
import { Segment } from 'semantic-ui-react';
import ToolbarWrapper from 'components/ToolbarWrapper';
import LocationsMap from 'components/locations/LocationsMap';

let knownLocations = [
  { 'ballarat-base-hospital': { center: { lat: -37.559319766335726, lng: 143.8471984929829 }, zoom: 21 } },
  { 'telstra-242-exhibition': { center: { lat: -37.80939906460477, lng: 144.9699905521843 }, zoom: 20 } },
  { 'telstra-175-liverpool': { center: { lat: -33.8771803, lng: 151.2105765 }, zoom: 20 } },
]

function getDefaultLocation() {
  return {
    center: { lat: -26.1772285, lng: 133.4170118 }, 
    zoom: 5
  }
}
function getCenterLocationOfAllDevices(locations) {
  console.log('getCenterLocationOfAllDevices');
  const maxLatitude = _.maxBy(locations, function(o) { return o.lastLatitude; }).lastLatitude;
  const minLatitude = _.minBy(locations, function(o) { return o.lastLatitude; }).lastLatitude;
  const maxLongitude = _.maxBy(locations, function(o) { return o.lastLongitude; }).lastLongitude;
  const minLongitude = _.minBy(locations, function(o) { return o.lastLongitude; }).lastLongitude;
  const centerLatitude = (maxLatitude + minLatitude)/2;
  const centerLongitude = (maxLongitude + minLongitude)/2;
  // console.log('###### center location', 'maxLatitude', maxLatitude, 'centerLatitude', centerLatitude, 'minLatitude', minLatitude, 'minLongitude', minLongitude, 'centerLongitude', centerLongitude, 'maxLongitude', maxLongitude );
  return {
    center: {
      lat: centerLatitude, lng: centerLongitude
    },
    zoom: 7
  }
}
class LocationsPage extends React.Component {
  handleMarkerClick(key, childProps) {
    console.log('handleMarkerClick:',key,childProps,this);
    this.setState({
      centerLocation: {
        center: {
          lat: childProps.lat, lng: childProps.lng
        }
      }
    });
  }
  handleMapBoundsChange(center,zoom,boundingBox) {
    console.log('handleBoundsChange:',center,zoom,boundingBox);
  }
  handleMapClick(a,b,c,d) {
    console.log('handleMapClick:','['+a.lng+','+a.lat+']');
  }
  getMapLocation(initalLocationId) {
    console.log('call getMapLocation.initalLocationId',initalLocationId);
    if (!_.some(knownLocations, initalLocationId) ) {
      console.log('center map on all devices');
      return getCenterLocationOfAllDevices(this.props.locations);
    }
    console.log('center map on location',initalLocationId);
    let location = _.find(knownLocations,initalLocationId);
    console.log('location[initalLocationId]',location[initalLocationId]);
    return location[initalLocationId];
  }
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      initalLocationId: null,
      centerLocation: getDefaultLocation()
    };
    this.updateWindowDimensions = this.updateWindowDimensions;
    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    if (!props.fetched) {
      console.log('start props.getLocations');
      props.getLocations();
    }
    /*
    else {
      // construct initalLocationId
      console.log('construct locationId:',this.props.match.params.id);
      this.state.locationId = this.props.match.params.id;
      // construct centerLocation
      const centerLocation = this.getMapLocation(this.state.locationId);
      console.log('construct centerLocation:',centerLocation);
      this.state.centerLocation = centerLocation;
    }
    */
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  componentDidUpdate(prevProps) {
    console.log('locationspage.componentDidUpdate',this.state,prevProps,this.props);
    
    if (!this.props.fetched) {
      console.log('no locations loaded');
      return;
    }
    console.log('locations loaded');
    // locations loaded
    if (this.props.fetched !== prevProps.fetched) {
      console.log('locations just loaded');
      // update centerLocation
      const centerLocation = this.getMapLocation(this.props.match.params.id);
      console.log('locations loaded:',this.props.match.params.id,centerLocation);
      this.setState({ centerLocation: centerLocation });
    }
    // location set to default
    const defaultLocation = getDefaultLocation();
    const currentLocation = this.state.centerLocation;
    if (defaultLocation.zoom >= currentLocation.zoom) {
      console.info('locations loaded but center zoom set to default');
      // update centerLocation
      const centerLocation = this.getMapLocation(this.props.match.params.id);
      console.log('update centerLocation:',this.props.match.params.id,centerLocation);
      this.setState({ centerLocation: centerLocation });
    }
    // new location selected
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // update locationId
      console.log('update initalLocationId:',this.props.match.params.id);
      this.setState({ locationId: this.props.match.params.id });
      // update centerLocation
      const centerLocation = this.getMapLocation(this.props.match.params.id);
      console.log('update centerLocation:',this.props.match.params.id,centerLocation);
      this.setState({ centerLocation: centerLocation });
    }
  }
  updateWindowDimensions = () => this.setState({ height: window.innerHeight - 42 });
  render() {
    const { getLocations, isAuthenticated, fetching, locations, userName, userRole } = this.props;
    const { centerLocation } = this.state;
    
    // TODO: move this logic server side
    var filteredDevices = locations;
    if ('bhs' === userRole) {
      filteredDevices = _.filter(locations, function(o) { return o.deviceFriendlyName.startsWith('Asset') });
    }

    return (
      <ToolbarWrapper
        activeItem='locations'
        isAuthenticated={isAuthenticated}
        userName={userName}
        userRole={userRole}
        contentFetching={fetching}
        refreshFunction={getLocations}
        refreshLabel={this.props.match.params.id}
      > 
        <Segment loading={fetching} basic style={{ margin: 0, padding: 0, position: 'absolute', top: '60px', left: 0, right: 0, bottom: 0 }}>
          <LocationsMap
            centerLocation={centerLocation}
            deviceLocations={filteredDevices}
            handleMapBoundsChange={this.handleMapBoundsChange}
            handleMapClick={this.handleMapClick}
            handleMarkerClick={this.handleMarkerClick}
          />
        </Segment>
      </ToolbarWrapper>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLocations: () => { dispatch(fetchLocations()) }
  }
}

const mapStateToProps = store => {
  console.log('LocationsPage store', store);
  return {
    isAuthenticated: store.auth.isAuthenticated,
    userName: store.auth.userName,
    userRole: store.auth.userRole,
    fetching: store.locations.fetching,
    fetched: store.locations.fetched,
    locations: store.locations.locations,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);
