import React from 'react';
import { connect } from 'react-redux';
import { Container, Responsive } from 'semantic-ui-react';
import Notifications from 'react-notification-system-redux';
import PageMenu from 'components/PageMenu';

class ToolbarWrapper extends React.Component {
  state = { sidebarVisible: false }
  show = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  handleOnUpdate = (e, { width }) => this.setState({ width })
  render() {
    const { activeItem, children, isAuthenticated, notifications, userName, userRole, contentCount, contentFetching, refreshFunction, refreshLabel } = this.props
    const { width } = this.state
    const isMobileView = width >= Responsive.onlyTablet.minWidth ? false : true
    return (
      <div>
        <PageMenu
          isAuthenticated={isAuthenticated}
          activeItem={activeItem}
          userName={userName}
          userRole={userRole}
          isMobileView={isMobileView}
          contentCount={contentCount}
          contentFetching={contentFetching}
          refreshFunction={refreshFunction}
          refreshLabel={refreshLabel}
        />
        <Container fluid>
          {children}
        </Container>
        <Notifications notifications={notifications} />
      </div>
    )
  }
}
const mapStateToProps = store => {
  console.log('Toolbar store', store);
  return {
    notifications: store.notifications
  }
}
export default connect(mapStateToProps)(ToolbarWrapper);
