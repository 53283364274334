import React from 'react';
import { simpleMarkerStyle, simpleMarkerStyleSubtitle, simpleMarkerStyleTitle, simpleMarkerStyleHover } from './SimpleMarkerStyle';

export default class DeviceMapPin extends React.Component {
	render() {
		const style = this.props.$hover ? simpleMarkerStyleHover : simpleMarkerStyle;
		return (
			<div style={style}>
				<div style={simpleMarkerStyleTitle}>{this.props.title}</div>
				<div style={simpleMarkerStyleSubtitle}>{this.props.floorKey}</div>
			</div>
		);
	}
}
