import 'semantic-ui-css/semantic.min.css';

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import routes from './routes';
import store from './store/store'
import { signinUserSuccess } from './actions/authActions';
import './styles/semantic.darkly.css';

export default class AppClient extends React.Component {
  render () {
    return (
      <Provider store={store}>
        {routes}
      </Provider>
    )
  }
}

const target = document.getElementById('app');

let token = localStorage.getItem('token');
if (token !== null) {
    store.dispatch(signinUserSuccess(token));
}

ReactDOM.render(<AppClient />, target);
