import React from 'react'
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import { signinUser } from '../actions/authActions'

const validate = values => {
  console.log('validating form', values)
  const errors = {}
  if (!values.email) {
    errors.email = 'Please enter a username.'
  }
  if (!values.password) {
    errors.password = 'Please enter a password.'
  }
  return errors
}

const formData = {
  email: '',
  password: ''
}

const renderField = ({ defaultValue, icon, placeholder, input, label, key, type, value, meta: { touched, error } }) => (
  <Form.Field>
      <Input {...input}
        icon={icon}
        iconPosition='left'
        label={{ tag: true, content: label }}
        labelPosition='right'
        placeholder={placeholder}
        type={type} 
      />
    {touched && error && <Message negative>{error}</Message>}
  </Form.Field>
)

class SigninForm extends React.Component {

  submit = (values) => {
    console.log('SigninForm.submit', values)
    this.props.dispatch( signinUser(values.email, values.password) );
  }

  render() {
    const { handleSubmit, statusText, error, invalid, submitting } = this.props
    let message = error
    if (statusText) {
        message = statusText
    }
    return (
      <div>
        {message && <Message>{message}</Message>}
        <Message attached header='Sign in' />
        <Form className='attached fluid segment' onSubmit={handleSubmit(this.submit)}>
          <Field name='email' type='text' component={renderField} placeholder='Username' label='Email' icon='mail' />
          <Field name='password' type='text' component={renderField} placeholder='Password' label='Password' icon='hide' />
          <Button color='blue' type='submit' disabled={invalid || submitting}>Sign in</Button>
        </Form>
        {/*
        <Message attached='bottom' warning>
          <div>Don't have an account? <Link to='/'>Register</Link></div>
          <div><Link to='/'>I forgot my password</Link></div>
        </Message>
        */}
      </div>
    )
  }
}

SigninForm = reduxForm({
  form: 'signinForm', // a unique identifier for this form
  initialValues: formData,
  validate // validation function given to redux-form
})(SigninForm)

const mapStateToProps = state => {
  return {
    statusText: state.auth.statusText
  }
}

export default connect(mapStateToProps)(SigninForm);
