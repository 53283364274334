import React from 'react';
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

class NotFoundPage extends React.Component {
  
  render() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      return (
        <Redirect to='/dashboard'/>
      )
    } else {
      return (
        <Redirect to='/'/>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps)(NotFoundPage);
