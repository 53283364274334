export default function reducer(state={
    devices: [],
    activeDevice: null,
    byId: {},
    fetching: false,
    fetched: false,
    error: null,
  }, action) {

    switch (action.type) {
      case "FETCH_DEVICES": {
        return {...state, fetching: true}
      }
      case "FETCH_DEVICES_REJECTED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          devices: action.payload,
        }
      }
      case "FETCH_DEVICES_FULFILLED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          devices: action.payload
            .sort((a, b) => b.lastSignalDateTime.localeCompare(a.lastSignalDateTime))
        }
      }
      case "FETCH_DEVICE": {
        return {...state, activeDevice: null}
      }
      case "FETCH_DEVICE_REJECTED": {
        return {
          ...state,
          activeDevice: null
        }
      }
      case "FETCH_DEVICE_FULFILLED": {
        const id = action.payload.id;
        state.byId[id] = action.payload;
        console.log("id", id, state.byId[id]);
        return {
          ...state,
          activeDevice: action.payload,
        }
      }
      case "ADD_DEVICE": {
        return {
          ...state,
          devices: [...state.devices, action.payload],
        }
      }
      case "UPDATE_DEVICE": {
        const { id } = action.payload
        const newDevices = [...state.devices]
        const deviceToUpdate = newDevices.findIndex(device => device.id === id)
        newDevices[deviceToUpdate] = action.payload;
        return {
          ...state,
          devices: newDevices,
        }
      }
      case "DELETE_DEVICES": {
        return {
          ...state,
          devices: state.devices.filter(message => message.id !== action.payload),
        }
      }
      default:
        break;
    }
    return state
}
