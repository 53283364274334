import React from 'react';
import GoogleMap from 'google-map-react';
import SimpleMarker from '../locations/markers/SimpleMarker';
// import ballaratBaseHospitalData from '../locations/data/ballarat-base-hospital.json';
// import telstra242ExhibitionData from '../locations/data/telstra-242-exhibition.json';

import * as Geo from './GeoLatLon.js';
import { FPOverlay, onAddFloorPlanOverlay, removeFloorPlanOverlay, showFloorPlanOverlay } from './FloorplanOverlay.js';
import floorplans from './floorplans.json';

const GOOGLE_MAP_API_KEY = 'AIzaSyAl90JBXyshAIPe6oxmJyNSEDvpOfnGnHk';
const mapKeys = {
  key: GOOGLE_MAP_API_KEY,
  language: 'en-AU',
  region: 'au',
};

var LatLonSpherical = Geo.LatLon;

function getMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    mapTypeId: 'roadmap',
    zoom:21
  };
}

function onLocationsMapLoaded({map, maps}) {
  console.log('onLocationsMapLoaded', map, maps, this);
  FPOverlay.prototype = new maps.OverlayView();
  FPOverlay.prototype.onAdd = onAddFloorPlanOverlay;
  FPOverlay.prototype.draw = drawFloorPlanOverlay;
  FPOverlay.prototype.removeOverlay = removeFloorPlanOverlay;
  FPOverlay.prototype.showOverlay = showFloorPlanOverlay;

  function drawFloorPlanOverlay() {
    // console.log('FloorplanOverlay.drawFloorPlanOverlay start');
    var projection = this.getProjection();
    // console.log('projection', projection);
    var points = {sw:null,nw:null,ne:null,se:null};
    for (var k in points) {
      points[k]= projection.fromLatLngToDivPixel(new maps.LatLng(this.vertices[k].lat, this.vertices[k].lng));
    }
    // console.log('# floorplan points', JSON.stringify(points));    
    if (this.div.childNodes.length){
      this.div.replaceChild(document.createElement('canvas'),this.div.firstChild)
    } else {
      this.div.appendChild(document.createElement('canvas'));
    }
    var canvas_style = {
      width : (Math.max(points.ne.x,points.se.x) - Math.min(points.nw.x,points.sw.x)),
      height: (Math.max(points.se.y,points.sw.y) - Math.min(points.nw.y,points.ne.y)),
      top   : Math.min(points.nw.y,points.ne.y),
      left  : Math.min(points.sw.x,points.nw.x)
    },
    canvas = this.div.firstChild,
    img = this.image,
    context;
    for (var l in canvas_style) {
      this.div.style[l] = canvas_style[l]+'px';  
    }
    canvas.width = canvas_style.width
    canvas.height = canvas_style.height
    context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas_style.width, canvas_style.height);
    context.setTransform(
      1, 
      Math.tan( -(90-this.bearings.x)*Math.PI/180 ), 
      Math.tan( (180-this.bearings.y)*Math.PI/180 ), 
      1, 
      ((Math.max(points.sw.x,points.nw.x)===points.sw.x)
      ? 0 : points.nw.x-canvas_style.left), points.nw.y-canvas_style.top
    );
    // context.globalAlpha = 0.6;
    // console.log('draw image',img);
    context.drawImage(img, 0, 0, points.ne.x-points.nw.x, points.sw.y-points.nw.y);
    // console.log('FloorplanOverlay.drawFloorPlanOverlay exit');
    img.onload = function() {
      // console.log('context.drawImage');
      context.drawImage(img, 0, 0, points.ne.x-points.nw.x, points.sw.y-points.nw.y);
    }
  };

  FPOverlay.prototype.onRemove = function() {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  };

  var fpOverlay = null;

  function updateFloorConfiguration(floorConfiguration) {
    console.log('updateFloorConfiguration',floorConfiguration);
    for (var ap in floorConfiguration.apInfo) {
      var theAP = floorConfiguration.apInfo[ap];
      if (theAP.elementType === 8) {
        console.log('ap',ap,'theAP',theAP);
        var tmp = theAP.pointStr.split(':');
        var maxXY = tmp[2].split(',');
        floorConfiguration.imgXInMetres = maxXY[0];
        floorConfiguration.imgYInMetres = maxXY[1];
        break;
      }
    }
    return floorConfiguration;
  }

  function loadAccessPoints(floorConfiguration) {
    console.log('loadAccessPoints',floorConfiguration);
    var accessPoints = [];
    for (var accessPoint in floorConfiguration.apInfo) {
      var theAP = floorConfiguration.apInfo[accessPoint];
      if (theAP.elementType === 15) {
        var xyInMetres = theAP.pointStr.split(',');
        var position = calculateLatLngFromXY(
          xyInMetres[0],
          xyInMetres[1],
          floorConfiguration.imgXInMetres,
          floorConfiguration.imgYInMetres,
          floorConfiguration.tl,
          floorConfiguration.tr,
          floorConfiguration.bl
        );
        accessPoints[accessPoint] = {
          position: position,
          label: theAP.name
        };
      }
    }
    return accessPoints;
  }

  function drawAccessPointMarkers(maps, map, accessPoints) {
    console.log('displayAccessPointMarkers', accessPoints);
    for (var accessPointKey in accessPoints) {
      var accessPoint = accessPoints[accessPointKey];
      // console.log('displayAccessPointMarkers accessPoint',accessPoint);
      const googlePosition = new maps.LatLng(accessPoint.position.lat, accessPoint.position.lon);
      new maps.Marker({
        position: googlePosition,
        label: accessPoint.name,
        icon: {
          path: maps.SymbolPath.CIRCLE,
          scale: 1,
        },
        map: map
      });
    }
  }

  function calculateLatLngFromXY(xInMetres, yInMetres, xBoundsInMetres, yBoundsInMetres, tl, tr, bl) {
    // console.log('### calculateLatLngFromXY', xyInMetres, xyBoundsInMetres, overlayRefs);
    var tl_ll = new LatLonSpherical(tl.lat, tl.lng);
    var tr_ll = new LatLonSpherical(tr.lat, tr.lng);
    var bl_ll = new LatLonSpherical(bl.lat, bl.lng);
    var bearingOverlayX  = tl_ll.bearingTo(tr_ll);
    var distanceOverlayMaxX = tl_ll.distanceTo(tr_ll);
    var bearingOverlayY = tl_ll.bearingTo(bl_ll);
    var distanceOverlayMaxY = tl_ll.distanceTo(bl_ll);
    var tmp_ll = tl_ll.destinationPoint(bearingOverlayX, distanceOverlayMaxX * xInMetres / xBoundsInMetres);
    var target_ll = tmp_ll.destinationPoint(bearingOverlayY, distanceOverlayMaxY * yInMetres / yBoundsInMetres);
    return {
      "lat": target_ll.lat,
      "lon": target_ll.lon
    }
  }

  function drawFloorplanOnMap(floorConfiguration) {
    console.log('drawFloorplanOnMap', floorConfiguration);
    const tl_ll = LatLonSpherical(floorConfiguration.tl.lat, floorConfiguration.tl.lng);
    const tr_ll = LatLonSpherical(floorConfiguration.tr.lat, floorConfiguration.tr.lng);
    const bl_ll = LatLonSpherical(floorConfiguration.bl.lat, floorConfiguration.bl.lng);
    const bearing_tl_tr  = tl_ll.bearingTo(tr_ll);
    const bearing_tl_bl = tl_ll.bearingTo(bl_ll);
    const distance_tl_bl = tl_ll.distanceTo(bl_ll);
    const br_ll = tr_ll.destinationPoint(bearing_tl_bl,distance_tl_bl);
    const path = [
        {lat:bl_ll.lat, lng:bl_ll.lon},
        {lat:tl_ll.lat, lng:tl_ll.lon},
        {lat:tr_ll.lat, lng:tr_ll.lon},
        {lat:br_ll.lat,lng:br_ll.lon}
    ];
    var img = new Image();
    // img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs="; // fpInfo.imgFile;
    img.src = '/assets/images/floorplans/' + floorConfiguration.imgFile;
    img.height = floorConfiguration.imgYInMetres; // TODO: changed from naturalHeight
    img.width = floorConfiguration.imgXInMetres; // TODO: changed from naturalWidth
    if (fpOverlay != null) {
      fpOverlay.removeOverlay();
      fpOverlay = null;
    }
    fpOverlay = new FPOverlay(
      img,// image, 
      map, // this,
      { x: bearing_tl_tr, y: bearing_tl_bl },
      { sw: path[0], nw: path[1], ne: path[2], se: path[3] }
    );
  }

  // load the floor configuration
  var floorConfiguration = floorplans[this.floorKey];
  console.log('loaded configuration for floorKey',this.floorKey,floorConfiguration);
  if (floorConfiguration) {
    // update the maxXY calculation
    floorConfiguration = updateFloorConfiguration(floorConfiguration);
    const accessPoints = loadAccessPoints(floorConfiguration);
    drawAccessPointMarkers(maps, map, accessPoints);
    drawFloorplanOnMap(floorConfiguration);
  }

}

class DeviceMap extends React.Component {
  _onChildClick = (key, childProps) => {
    console.log('_onChildClick', key, childProps);
  }
  _onChildMouseEnter = (key /*, childProps */) => {
    console.log('_onChildMouseEnter');
  }
  _onChildMouseLeave = (/* key, childProps */) => {
    console.log('_onChildMouseLeave');
  }
  render() {
    const { floorKey, latitude, longitude, name, handleMapClick, handleMarkerClick} = this.props;
    console.log('display floorKey',floorKey);
    const marker = (
      <SimpleMarker
        // required props
        key={name}
        lat={latitude}
        lng={longitude}
        // user props
        title={name}
        floorKey={floorKey}
        handleMarkerClick={handleMarkerClick} />
    );
    return (
      <GoogleMap
        options={getMapOptions}
        floorKey={floorKey}
        bootstrapURLKeys={mapKeys}
        center={{ lat: latitude, lng: longitude }}
        zoom={21}
        hoverDistance={20 / 2}
        onClick={handleMapClick}
        onChildClick={handleMarkerClick}
        onChildMouseEnter={this._onChildMouseEnter}
        onChildMouseLeave={this._onChildMouseLeave}
        onGoogleApiLoaded={onLocationsMapLoaded}
        yesIWantToUseGoogleMapApiInternals>
        {marker}
      </GoogleMap>
    )
  }
}
export default DeviceMap;
