import React from 'react';
import { connect } from 'react-redux';
import { fetchDevice } from 'actions/devicesActions'
import { Segment } from 'semantic-ui-react';
import PageHeaderCard from 'components/PageHeaderCard';
import ToolbarWrapper from 'components/ToolbarWrapper';
// import DeviceHistoryChart from 'components/device/DeviceHistoryChart';
import DeviceMap from 'components/device/DeviceMap';
// import DeviceTable from 'components/device/DeviceTable';
// import Style from 'styles/Style';


class DevicePage extends React.Component {
  constructor(props) {
    super(props);
    // load device
    const id = props.match.params.id;
    console.log('device/id', id);
    props.getDevice(id);
  }

  render() {
    const { byId, getDevice, isAuthenticated, match, userName, userRole } = this.props
    const id = match.params.id;
    console.log('Loading device', id);
    const device = byId[id];
    console.log('Displaying device', id, device);
    if (!device) {
      return (
        <ToolbarWrapper activeItem='devices' isAuthenticated={isAuthenticated} userName={userName} userRole={userRole}>
          <PageHeaderCard refreshFunction={() => getDevice(id)}/>
          <Segment loading={true} basic>
          </Segment>
        </ToolbarWrapper>
      )
    }
    console.log('Displaying device', device);
    let refreshLabel = device.deviceFriendlyName;
    if (device.floorId) {
      refreshLabel += ' on ' + device.floorId;
    }

    return (
      <ToolbarWrapper
        activeItem='devices'
        isAuthenticated={isAuthenticated}
        userName={userName}
        userRole={userRole}
        contentFetching={false}
        contentCount={null}
        refreshFunction={() => getDevice(id)}
        refreshLabel={refreshLabel}
      >
        <Segment basic style={{ margin: 0, padding: 0, position: 'absolute', top: '60px', left: 0, right: 0, bottom: 0 }}>
          <DeviceMap floorKey={device.floorId} latitude={device.lastLatitude} longitude={device.lastLongitude} name={device.deviceFriendlyName} />
        </Segment>
      </ToolbarWrapper>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getDevice: (id) => {
      dispatch(fetchDevice(id));
    }
  }
}
const mapStateToProps = store => {
  console.log('DevicePage store', store);
  return {
    isAuthenticated: store.auth.isAuthenticated,
    userName: store.auth.userName,
    userRole: store.auth.userRole,
    byId: store.devices.byId,
    activeDevice: store.devices.activeDevice,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DevicePage);

